import { createTheme } from '@mui/material/styles';

export const colors = {
  // primary: '#009cd9',
  // btnPrimary: '#009cd9',
  primary: '#090FAF',
  btnPrimary: '#090FAF',
  darkPrimary: '#005996',
  accent: '#00ACEE',
  inputBackground: 'rgba(195, 197, 199, 0.2)',
  inputText: '#9BA6A5',
  inputErrorBackground: 'rgba(255, 148, 148, 0.2)',
  inputErrorText: '#FF9494',
  fontError: '#EA5261',
  inputErrorBorder: '#EA5261',
  font: '#484f54',
  cancelCollect: 'rgba(255, 120, 120, 1)',
  green: '#E6F6E5',
  darkGreen: '#E0FFFF',
  gray: '#484f54',
  lightGray: '#F4F4F4',
  red: '#FF0000',
  blue: '#005996',
  darkblue: '#005996',
  secondary: '#6C757D',
  border: '#E0E0E0',
  success: '#28A745',
  warning: '#FFC107',
  danger: '#DC3545',
  info: '#17A2B8',
  muted: '#6C757D',
  light: '#F8F9FA',
  dark: '#343A40',
  white: '#FFFFFF',
  black: '#000000',
};
const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.darkPrimary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: colors.background,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.danger,
      contrastText: colors.white,  // Cor de erro definida
    },
    info: {
      main: colors.info,
    },
    text: {
      primary: colors.font,
      secondary: colors.muted,
    },
  },
  typography: {
    fontFamily: 'Nunito, sans-serif'
  },
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.danger, // Cor de fundo para Snackbar de erro
          color: colors.white,  // Texto branco no Snackbar
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: colors.danger,  // Cor de fundo específica do SnackbarContent
          color: colors.white,  // Texto branco no conteúdo do Snackbar
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          textTransform: 'none',
          padding: '12px 24px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          borderRadius: '8px',
        },
      },
    },
  },
});

export default theme;