import React from 'react';
import { Box, Typography } from '@mui/material';

const PoliticaPrivacidade = () => (
  <Box
    sx={{
      minHeight: '100vh',
      padding: 4,
      background: '#f5f5f5',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '90%'
    }}
  >
    <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
      Política de Privacidade
    </Typography>
    <Typography variant="body1" sx={{ textAlign: 'justify', maxWidth: '800px' }}>
      [Adicione aqui o texto completo da sua política de privacidade.]
    </Typography>
  </Box>
);

export default PoliticaPrivacidade;