// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import PartnerStores from './pages/PartnerStores.jsx';
import StoreDetails from './pages/StoreDetails.jsx';
import PartnerEcopoints from './pages/PartnerEcopoints.jsx';
import TransferPoints from './pages/TransferPoints.jsx';
import UserSignup from './pages/UserSignup.jsx';
import OperatorHome from './pages/operadorHome.jsx';
import RegisterDonation from './pages/RegisterDonation.jsx';
import RegisterRedemption from './pages/RegisterRedemption.jsx';
import LandingPage from './pages/LandingPage.jsx';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade.jsx'; 
import EcopointInfo from './pages/EcopointInfo';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rota para a Landing Page */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={
          <ProtectedRoute>
        <Dashboard />
        </ProtectedRoute>
      } />
        
        {/* Rotas públicas */}
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro" element={<UserSignup />} />

        {/* Rotas protegidas */}
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } 
        />
        {/* <Route 
          path="/descarte/:ecopointId" 
          element={
              <Register />
          } 
        /> */}
        {/* Rota de exibição do ecoponto (EcopointInfo.jsx) */}
        <Route path="/descarte/:ecopointId" element={
          <ProtectedRoute>
        <Register />
        </ProtectedRoute>} />

        {/* Rota de registro de descarte (reutiliza Register.jsx) */}
        {/* <Route path="/descarte/:ecopointId/registrar" element={<Register />} />        */}
        {/* <Route path="/lojas-parceiras" element={<PartnerStores />} />
        <Route path="/store-details" element={<StoreDetails />} />
        <Route path="/ecopontos" element={<PartnerEcopoints />} /> */}
        <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
        <Route 
          path="/transferencia" 
          element={
            <ProtectedRoute>
              <TransferPoints />
            </ProtectedRoute>
          }
        />
        <Route 
          path="/home-operador" 
          element={
            <ProtectedRoute>
              <OperatorHome />
            </ProtectedRoute>
          }
        />
        <Route 
          path="/registrar-doacao" 
          element={
            <ProtectedRoute>
              <RegisterDonation />
            </ProtectedRoute>
          }
        />
        <Route 
          path="/registrar-compra" 
          element={
            <ProtectedRoute>
              <RegisterRedemption />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;