import React, { useState } from 'react';
import { Box, TextField, Typography, Snackbar, Alert, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import apiSGR from '../services/apiSGR';
import getAddress from '../services/getAddress';
import CPFInput from '../components/CPFInput';
import TermsModal from '../modals/termsModal';

const validationSchema = yup.object().shape({
  name: yup.string().required('Nome completo é obrigatório'),
  document: yup
    .string()
    .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'Entre com um CPF válido')
    .required('CPF é obrigatório'),
  phone_number: yup
    .string()
    .matches(/\([1-9]{2}\) (?:[2-9]|9[1-9])[0-9]{4}\-[0-9]{4}$/, 'Entre com um celular válido')
    .required('Celular é obrigatório'),
  email: yup.string().email('Entre com um e-mail válido').required('E-mail é obrigatório'),
  // address: yup.object().shape({
  //   zip_code: yup.string().matches(/[0-9]{5}\-[0-9]{3}$/, 'Entre com um CEP válido').required('CEP é obrigatório'),
  //   number: yup.string().required('Número é obrigatório'),
  //   street: yup.string().required('Rua é obrigatória'),
  //   city: yup.string().required('Cidade é obrigatória'),
  //   state: yup.string().required('Estado é obrigatório'),
  //   neighborhood: yup.string().required('Bairro é obrigatório'),
  // }),
  password: yup.string().min(6, 'Senha deve ter no mínimo 6 caracteres').required('Senha é obrigatória'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não são compatíveis')
    .required('Confirmação de senha é obrigatória'),
});

export default function UserSignup() {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleAddressSearch = async (zip_code, setFieldValue) => {
    try {
      const result = await getAddress(zip_code);
      if (result) {
        setFieldValue('address.street', result.street);
        setFieldValue('address.city', result.city);
        setFieldValue('address.state', result.state);
        setFieldValue('address.neighborhood', result.neighborhood);
      } else {
        throw new Error('CEP não encontrado');
      }
    } catch (error) {
      setSnackbarMessage('CEP inválido ou erro ao buscar o endereço.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleSubmit = async (values) => {
    try {
      await apiSGR.post('users', {
        ...values,
        document: values.document.replace(/\D/g, ''),
        phone_number: values.phone_number.replace(/\D/g, ''),
        role: { name: 'Gerador' },
        active: 1,
        type: 0,
      });
      setSnackbarMessage('Usuário criado com sucesso!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      navigate('/login');
    } catch (error) {
      setSnackbarMessage('Erro ao criar usuário. Verifique os dados.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `linear-gradient(135deg, #090FAF 30%, #00ACEE 90%)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 3,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: 400,
          width: '100%',
          padding: 3,
          borderRadius: '16px',
          background: '#fff',
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" color="primary" sx={{ textAlign: 'center', mb: 3 }}>
          Criar Conta
        </Typography>
        

        <Formik
          initialValues={{
            name: '',
            document: '',
            phone_number: '',
            email: '',
            address: { zip_code: '', number: '', complement: '', street: '', city: '', state: '', neighborhood: '' },
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nome completo"
                variant="outlined"
                fullWidth
                value={values.name}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                sx={{ mb: 2 }}
              />

              <CPFInput
                value={values.document}
                onChange={handleChange('document')}
                error={touched.document && errors.document}
              />

              <InputMask
                mask="(99) 99999-9999"
                value={values.phone_number}
                onChange={handleChange('phone_number')}
              >
                {() => (
                  <TextField
                    label="Celular"
                    variant="outlined"
                    fullWidth
                    error={touched.phone_number && Boolean(errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                    sx={{ mb: 2 }}
                  />
                )}
              </InputMask>

              <TextField
                label="E-mail"
                variant="outlined"
                fullWidth
                value={values.email}
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ mb: 2 }}
                inputProps={{
                  autoCapitalize: 'none', // Desabilita a capitalização automática
                  autoCorrect: 'off',     // Desabilita a correção automática
                  spellCheck: 'false',    // Desabilita a verificação ortográfica
                }}
              />

              {/* <InputMask
                mask="99999-999"
                value={values.address.zip_code}
                onChange={(e) => {
                  setFieldValue('address.zip_code', e.target.value);
                  if (e.target.value.replace(/\D/g, '').length === 8) {
                    handleAddressSearch(e.target.value, setFieldValue);
                  }
                }}
              >
                {() => (
                  <TextField
                    label="CEP"
                    variant="outlined"
                    fullWidth
                    error={touched.address?.zip_code && Boolean(errors.address?.zip_code)}
                    helperText={touched.address?.zip_code && errors.address?.zip_code}
                    sx={{ mb: 2 }}
                  />
                )}
              </InputMask>

              <TextField
                label="Rua"
                variant="outlined"
                fullWidth
                value={values.address.street}
                disabled
                sx={{ mb: 2 }}
              />

              <TextField
                label="Número"
                variant="outlined"
                fullWidth
                value={values.address.number}
                onChange={handleChange('address.number')}
                onBlur={handleBlur('address.number')}
                error={touched.address?.number && Boolean(errors.address?.number)}
                helperText={touched.address?.number && errors.address?.number}
                sx={{ mb: 2 }}
              /> */}

              <TextField
                label="Senha"
                variant="outlined"
                fullWidth
                type="password"
                value={values.password}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ mb: 2 }}
              />

              <TextField
                label="Confirme a senha"
                variant="outlined"
                fullWidth
                type="password"
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
                onBlur={handleBlur('confirmPassword')}
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ mb: 2 }}
              />
                            <Typography
          variant="body2"
          sx={{ textAlign: 'center', mb: 2, cursor: 'pointer', textDecoration: 'underline', color: 'primary.main' }}
          onClick={handleOpenModal}
        >
          Termos de Uso e Política de Privacidade
        </Typography>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  textTransform: 'none',
                  borderRadius: '50px',
                  padding: '12px',
                  mb: 2,
                }}
              >
                Criar Conta
              </Button>

            </form>
          )}
        </Formik>
        <TermsModal open={openModal} onClose={handleCloseModal} />

        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={handleBack}
          sx={{
            textTransform: 'none',
            borderRadius: '50px',
            padding: '12px',
            mt: 2
          }}
        >
          Voltar
        </Button>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}