// src/components/Register.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, Alert, Paper, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import apiService from '../services/apiService';
import { jwtDecode } from 'jwt-decode'; // Remova as chaves na importação
import { SuccessDialog } from '../components/SuccessDialog';

const Register = () => {
  const [ecopointName, setEcopointName] = useState('');
  const [location, setLocation] = useState('');
  const [pointsGained, setPointsGained] = useState(0);
  const [newBalance, setNewBalance] = useState(0);
  const [userName, setUserName] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [imageUrl, setImgUrl] = useState('');

  const navigate = useNavigate();
  const { ecopointId } = useParams();
  const apiUpload = process.env.REACT_APP_API_SGR_UPLOAD;

  useEffect(() => {
    const fetchEcopointDetails = async () => {
      try {
        const response = await apiService.get(`ecopoints/${ecopointId}`);
        if (!response || !response.name) {
          navigate('/dashboard'); // Redireciona para a página inicial se o ecoponto não for encontrado
          return;
        }
        setEcopointName(response.name || '');
        setLocation(response.address || 'Endereço não disponível');
        setPointsGained(response.points || 10);
        setImgUrl(response.img ? `${apiUpload}/${response.img}` : null);
      } catch (error) {
        setError('Erro ao carregar os detalhes do Ecoponto.');
        setOpenSnackbar(true);
        navigate('/dashboard')
      }
    };

    fetchEcopointDetails();
  }, [ecopointId, apiUpload, navigate]);

  const handleRegister = async () => {
    try {
      const tokenEcoponto = localStorage.getItem('tokenEcoponto');
      const decodedtokenEcoponto = jwtDecode(tokenEcoponto);
      const cpf = decodedtokenEcoponto.cpf.replace(/\D/g, '');

      const result = await apiService.post('disposals', { ecopointId, cpf, points: pointsGained }, tokenEcoponto);

      setUserName(result.userName);
      setNewBalance(result.newBalance);
      setOpenSuccessDialog(true);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Erro ao registrar o descarte.';
      
      if (errorMessage === 'Você só pode fazer um descarte por hora.') {
        setError('Você já realizou um descarte recentemente. Tente novamente em uma hora.');
      } else if (errorMessage === 'Unauthorized') {
        setError('Seu tokenEcoponto expirou. Por favor, faça login novamente.');
        localStorage.removeItem('tokenEcoponto');
        navigate('/login');
      } else {
        setError(errorMessage);
      }
      setOpenSnackbar(true); // Mostra o Snackbar em caso de erro
    }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate('/dashboard');
  };

  const handleErrorClose = () => {
    setError('');
    setOpenSnackbar(false);
  };

  const handleBack = () => {
    navigate('/dashboard'); // Verifique se a rota está correta
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `linear-gradient(135deg, #090FAF 30%, #00ACEE 90%)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        p: 3,
        boxSizing: 'border-box',
      }}
    >
      {/* Logo do Ecoponto */}
      {imageUrl && (
        <Box
          component="img"
          src={imageUrl}
          alt="Logo do Ecoponto"
          sx={{
            width: 300,
            height: 300,
            borderRadius: '50%',
            objectFit: 'cover',
            marginBottom: 2,
            boxShadow: 3,
          }}
        />
      )}

      {/* Texto de Explicação */}
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: 300,
          p: 3,
          mb: 3,
          borderRadius: '16px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" color="textSecondary">
          Ganhe <strong>{pointsGained} pontos</strong> ao descartar seus resíduos neste ecoponto.
        </Typography>
      </Paper>

      {/* Botão de Registrar */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleRegister}
        sx={{
          maxWidth: 350,
          mb: 2,
          borderRadius: '50px',
          textTransform: 'none',
          padding: '12px',
        }}
      >
        Registrar Descarte
      </Button>

      {/* Botão de Voltar */}
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleBack}
        sx={{
          maxWidth: 350,
          borderRadius: '50px',
          textTransform: 'none',
          padding: '12px',
        }}
      >
        Voltar
      </Button>

      {/* Snackbar de Erro */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      {/* Success Dialog */}
      {openSuccessDialog && (
        <SuccessDialog
          open={openSuccessDialog}
          onClose={handleCloseSuccessDialog}
          userName={userName}
          ecopointName={ecopointName}
          pointsGained={pointsGained}
          newBalance={newBalance}
        />
      )}
    </Box>
  );
};

export default Register;