import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppStoreImage from '../assets/appstore.png'; // Imagem do App Store
import PlayStoreImage from '../assets/googleplay.png'; // Imagem do Google Play
import heroImage from '../assets/hero.png'; // Imagem do Hero
import logoEco from '../assets/logo-eco.png'; // Logo da Eco.UFRJ

const LandingPage = () => {
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate('/cadastro');
  };
  const handleLogin = () => {
    navigate('/login');
  };

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `linear-gradient(135deg, #090FAF 30%, #00ACEE 90%)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        p: 3,
        boxSizing: 'border-box',
      }}
    >
      {/* Logos no topo */}
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 3 }}>
        <Box component="img" src={logoEco} alt="Eco.UFRJ" sx={{ height: 120 }} />
      </Box>

      {/* Título e descrição */}
      <Typography variant="h5" color="white" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Seja Bem-vindo!
      </Typography>
      <Typography variant="body1" color="white" paragraph sx={{ textAlign: 'center', maxWidth: 300 }}>
        Ganhe pontos ao descartar resíduos em nosso ecoponto e ao doar produtos em nossa loja de economia circular.  
        Troque seus pontos por produtos em nossa loja!
      </Typography>

      {/* Hero Image */}
      <Box
        component="img"
        src={heroImage}
        alt="Sirí App"
        sx={{
          width: '100%',
          maxWidth: 250,
          height: 'auto',
          borderRadius: '16px',
          boxShadow: 3,
          my: 2,
        }}
      />

      {/* Botões de Cadastro e Login */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          mt: 3,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSignup}
          sx={{
            borderRadius: '50px',
            textTransform: 'none',
            px: 4,
            py: 1,
            fontSize: '2rem',
            width: '100%',
            maxWidth: 300,
          }}
        >
          Cadastrar
        </Button>

        <Typography variant="body1" color="white" paragraph sx={{ textAlign: 'center', maxWidth: 300, mt: 3 }}>
          Já se cadastrou?
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLogin}
          sx={{
            borderRadius: '50px',
            textTransform: 'none',
            px: 4,
            py: 1,
            fontSize: '1rem',
            width: '100%',
            maxWidth: 300,
          }}
        >
          Entrar
        </Button>
      </Box>


      <Typography variant="h6" color="white" align="center" gutterBottom sx={{ mt: 3 }}>
        Disponível nas principais lojas
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', p: 2 }}>
        <Box
          component="img"
          src={AppStoreImage}
          alt="App Store"
          sx={{ height: 40, cursor: 'pointer' }}
          onClick={() => handleDownload('https://apps.apple.com/br/app/sir%C3%AD-gest%C3%A3o-de-res%C3%ADduos/id6502182657?l=en-GB')}
        />
        <Box
          component="img"
          src={PlayStoreImage}
          alt="Google Play"
          sx={{ height: 40, cursor: 'pointer' }}
          onClick={() => handleDownload('https://play.google.com/store/apps/details?id=com.sgrsiri.sirigeradorapp')}
        />
      </Box>

      <Typography variant="body2" color="white" align="center" paragraph sx={{ maxWidth: 300 }}>
        Toque nos botões acima para baixar o aplicativo diretamente da sua loja preferida.
      </Typography>
    </Box>
  );
};

export default LandingPage;