import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Paper,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import logoEco from '../assets/logo-eco-azul.png';
import apiService from '../services/apiService';

export default function Dashboard() {
  const [userName, setUserName] = useState('');
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(''); // Estado de erro
  const [openSnackbar, setOpenSnackbar] = useState(false); // Estado para Snackbar

  const handleLogout = () => {
    localStorage.removeItem('tokenEcoponto');
    window.location.href = '/login'; // Redireciona para a tela de login
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const tokenEcoponto = localStorage.getItem('tokenEcoponto');
      try {
        // Inicia o carregamento
        setLoading(true);

        // Fetch user info
        const userInfo = await apiService.get('users/me', tokenEcoponto);
        setUserName(userInfo.name || 'Usuário');
        setBalance(userInfo.points || 0);

        // Fetch transactions
        const disposals = await apiService.get('users/disposals', tokenEcoponto);
        const redemptions = await apiService.get('users/redemptions', tokenEcoponto);
        const donations = await apiService.get('users/donations', tokenEcoponto);
        const transfers = await apiService.get('users/transfers', tokenEcoponto);
        const transfersRecieved = await apiService.get('users/recieved', tokenEcoponto);

        const allTransactions = [
          ...disposals.map((d) => ({
            type: 'gain',
            description: `Descarte no Ecoponto ${d.ecopoint?.name}`,
            points: d.points,
            date: new Date(d.createdAt),
          })),
          ...redemptions.map((r) => ({
            type: 'redeem',
            description: `Resgate na Loja ${r.store?.name}: ${r.description}`,
            points: -r.points,
            date: new Date(r.createdAt),
          })),
          ...donations.map((d) => ({
            type: 'donation',
            description: `Doação para ${d.store?.name}: ${d.description}`,
            points: d.value,
            date: new Date(d.createdAt),
          })),
          ...transfers.map((t) => ({
            type: 'transfer',
            description: `Transferência de ${t.points} pontos para ${t.recipient?.name || 'Usuário desconhecido'}`,
            points: -t.points,
            date: new Date(t.createdAt),
          })),
          ...transfersRecieved.map((tr) => ({
            type: 'transfer',
            description: `Transferência recebida de ${tr.sender?.name || 'Usuário desconhecido'}`,
            points: tr.points,
            date: new Date(tr.createdAt),
          })),
        ];

        const sortedTransactions = allTransactions.sort((a, b) => b.date - a.date);
        setTransactions(sortedTransactions);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        setError('Erro ao carregar dados. Por favor, tente novamente mais tarde.');
        setOpenSnackbar(true);
      } finally {
        // Finaliza o carregamento
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleErrorClose = () => {
    setOpenSnackbar(false);
  };

  const filteredTransactions = transactions.filter(
    (transaction) => filter === 'all' || transaction.type === filter
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `linear-gradient(135deg, #f0f4f7 30%, #e8f1fa 90%)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          width: '100%',
          maxWidth: 1200,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2,
          background: '#fff',
          borderRadius: 2,
          boxShadow: 3,
          mb: 3,
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
      >
        <Box component="img" src={logoEco} alt="Logo Eco" sx={{ height: 50 }} />
        <IconButton onClick={handleLogout} color="primary" sx={{ padding: 1.5 }}>
          <LogoutIcon />
        </IconButton>
      </Box>

      {/* Condicionalmente renderiza o conteúdo ou o carregamento */}
      {loading ? (
        // Indicador de Carregamento
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Aguarde, estamos buscando seus dados...
          </Typography>
        </Box>
      ) : (
        // Conteúdo da Dashboard
        <>
          {/* Saudação */}
          <Typography variant="h5" sx={{ color: '#0277bd', mb: 2, textAlign: 'center' }}>
            Olá, <strong>{userName}</strong>!
          </Typography>

          {/* Saldo */}
          <Paper
            sx={{
              width: '100%',
              maxWidth: 500,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#fff',
              borderRadius: 2,
              padding: 2,
              mb: 3,
              boxShadow: 3,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#0277bd' }}>
              Saldo
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar sx={{ bgcolor: '#00c853' }}>
                <ArrowUpwardIcon />
              </Avatar>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00c853' }}>
                {balance}
              </Typography>
            </Box>
          </Paper>

          {/* Atalhos */}
          {/* <Grid container spacing={2} sx={{ maxWidth: 500, mb: 3 }}>
            {[
              { label: 'Ecopontos', icon: <LocationOnIcon /> },
              { label: 'Lojas', icon: <ShoppingBagIcon /> },
              { label: 'Transferir', icon: <CurrencyExchangeIcon /> },
            ].map((option, index) => (
              <Grid item xs={4} key={index}>
                <Paper
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2,
                    background: '#e1f5fe',
                    borderRadius: 2,
                    cursor: 'pointer',
                    '&:hover': { background: '#b3e5fc' },
                  }}
                >
                  {option.icon}
                  <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                    {option.label}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid> */}

          {/* Filtro de Transações */}
          <Box sx={{ width: '100%', maxWidth: 500, mb: 2 }}>
            <Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              fullWidth
              sx={{ background: '#fff', borderRadius: 2, mb: 2 }}
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="gain">Ganhos</MenuItem>
              <MenuItem value="redeem">Resgates</MenuItem>
              <MenuItem value="donation">Doações</MenuItem>
              <MenuItem value="transfer">Transferências</MenuItem>
            </Select>
          </Box>

          {/* Lista de Transações */}
          <Box sx={{ width: '100%', maxWidth: 500, overflowY: 'auto' }}>
            {filteredTransactions.length > 0 ? (
              filteredTransactions.map((t, i) => (
                <Box
                  key={i}
                  sx={{
                    background: '#fff',
                    padding: 2,
                    borderRadius: 2,
                    mb: 2,
                    boxShadow: 2,
                  }}
                >
                  <Typography variant="body1">{t.description}</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: t.points > 0 ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {t.points > 0 ? `+${t.points}` : t.points} pontos
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#999' }}>
                    {t.date.toLocaleDateString()}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ color: '#999', textAlign: 'center' }}>
                Nenhuma transação encontrada.
              </Typography>
            )}
          </Box>
        </>
      )}

      {/* Snackbar de erro */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}