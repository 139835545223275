import React, { useState } from 'react';
import { Box, TextField, Typography, Snackbar, Alert, Button, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import apiSGR from '../services/apiSGR';
import LogoEco from '../assets/logo-eco.png';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Por favor, insira um email válido.');
      setOpenSnackbar(true);
      return false;
    }
    if (password.length < 6) {
      setError('A senha deve ter pelo menos 6 caracteres.');
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      const response = await apiSGR.post('login', { email, password });
      localStorage.setItem('tokenEcoponto', response.access_token);
      const decodedToken = jwtDecode(response.access_token);
      const role = decodedToken.role;

      // Redireciona para a página original ou rota padrão
      const redirectTo = location.state?.from?.pathname || (role === 2 || role === 5 ? '/home-operador' : '/dashboard');
      navigate(redirectTo);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Erro ao conectar com o servidor.';
      setError(errorMessage);
      setOpenSnackbar(true);
    }
  };

  const handleErrorClose = () => {
    setOpenSnackbar(false);
  };

  const handleSignupRedirect = () => {
    navigate('/cadastro');
  };

  const handleBack = () => {
    navigate('/'); // Volta para a página anterior
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `linear-gradient(135deg, #090FAF 30%, #00ACEE 90%)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 3,
      }}
    >
      {/* Botão de Voltar */}
      <Box sx={{ position: 'absolute', top: 16, left: 16 }}>
        <IconButton onClick={handleBack} sx={{ color: 'white' }}>
          <ArrowBack />
        </IconButton>
      </Box>

      {/* Imagem Hero */}
      <Box
        component="img"
        src={LogoEco}
        alt="Login Hero"
        sx={{
          width: '200px',
          borderRadius: '3%',
          marginBottom: 10,
        }}
      />

      {/* Título e descrição */}
      {/* <Typography variant="h4" color="white" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1 }}>
        Bem-Vindo Novamente!!!
      </Typography>
      <Typography variant="body1" color="white" sx={{ textAlign: 'center', mb: 3 }}>
        Sistema de Pontuação para Sustentabilidade - Transforme ações sustentáveis em recompensas!
      </Typography> */}

      {/* Formulário */}
      <Box
        sx={{
          width: '90%',
          maxWidth: 350,
          background: '#fff',
          borderRadius: 2,
          boxShadow: 3,
          padding: 3,
        }}
      >
        <Typography variant="h5" color="primary" sx={{ textAlign: 'center', mb: 2 }}>
          Informe seus dados 
        </Typography>

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          autoComplete="email"
          type="email" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
          inputProps={{
            autoCapitalize: 'none', // Desabilita a capitalização automática
            autoCorrect: 'off',     // Desabilita a correção automática
            spellCheck: 'false',    // Desabilita a verificação ortográfica
          }}
        />
        <TextField
          label="Senha"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 3 }}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{
            textTransform: 'none',
            borderRadius: '50px',
            padding: '12px',
          }}
        >
          Entrar
        </Button>

        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', mt: 2 }}>
          Não tem uma conta?{' '}
          <Typography
            component="span"
            sx={{
              color: '#090FAF',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={handleSignupRedirect}
          >
            Cadastre-se
          </Typography>
        </Typography>
      </Box>

      {/* Snackbar de erro */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;