import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';

const TermsModal = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
        Política de Privacidade e Termos de Uso
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        <strong>1. Introdução</strong><br/>
        Estamos comprometidos em proteger seus dados pessoais e garantir que todas as operações estejam em conformidade com
        a Lei Geral de Proteção de Dados (LGPD). O uso do sistema implica na aceitação destes termos.
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        <strong>2. Finalidade da Coleta de Dados</strong> <br/> 
        Seus dados serão coletados e utilizados exclusivamente para:
        <ul>
          <li>Gerenciamento de pontos por ações sustentáveis (descartes, doações, resgates e transferências).</li>
          <li>Transparência nas transações realizadas no sistema.</li>
          <li>Geração de análises e relatórios de uso da plataforma.</li>
        </ul>
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        <strong>3. Direitos do Usuário</strong>  <br/>
        Você tem direito a:
        <ul>
          <li>Acessar, corrigir e excluir seus dados pessoais.</li>
          <li>Revogar o consentimento para uso dos seus dados.</li>
          <li>Solicitar a portabilidade dos seus dados para outro sistema.</li>
        </ul>
        Para exercer seus direitos, entre em contato conosco pelo e-mail: <strong>contato@reciclesiri.com.br</strong>.
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        <strong>4. Segurança e Retenção de Dados</strong>  <br/>
        Seus dados estão protegidos contra acessos não autorizados e serão armazenados apenas pelo tempo necessário para
        cumprir as finalidades descritas neste documento.
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        <strong>5. Uso Indevido e Penalidades</strong>  <br/>
        É estritamente proibido acessar, utilizar ou extrair dados do sistema para fins não autorizados. Qualquer tentativa
        de violação resultará em penalidades legais e exclusão do sistema.
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        <strong>6. Alterações nos Termos</strong>  <br/>
        Reservamo-nos o direito de alterar estes termos a qualquer momento. Os usuários serão notificados sobre mudanças
        significativas.
      </Typography>
      <Typography variant="body2" sx={{ mb: 4 }}>
        Para mais informações, entre em contato conosco através do e-mail ou telefone informado em nossa plataforma.
      </Typography>
      <Button variant="contained" color="primary" onClick={onClose} fullWidth>
        Fechar
      </Button>
    </Box>
  </Modal>
);

export default TermsModal;